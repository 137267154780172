import React from 'react'
import { ComfortTerm } from '@shared/components/sections/comfort-terms/model'
import CashInShape from '@shared/svg/in-shape/cash.svg'
import DocsInShape from '@shared/svg/in-shape/docs.svg'
import GrowInShape from '@shared/svg/in-shape/grow.svg'
import OfficeInShape from '@shared/svg/in-shape/office.svg'
import PercentRoundInShape from '@shared/svg/in-shape/percentRound.svg'
import TimeInShape from '@shared/svg/in-shape/time.svg'
import { type JournalPoint } from 'screens/about/sections/journal/data'

import styles from './style.module.scss'

export const titleFeedback = (
	<>
		Команда Credit.Club работает с 2012 года.
		<br /> На полном доверии клиентов
	</>
)

export const journalPointsTabLoan: JournalPoint[] = [
	{
		content: 'Плюсы и минусы кредита под залог недвижимости',
		imageUrl: 'journal-item1-new.jpg',
		imageUrlMobile: 'journal-item1-mob-new.png',
		href: 'https://journal.credit.club/chto-takoe-kvalifitsirovannaya-elektronnaya-podpis',
		imageClassName: styles.image,
	},
	{
		content: 'Как отказаться от страховки по кредиту',
		imageUrl: 'journal-item2-new.jpg',
		imageUrlMobile: 'journal-item2-mob-new.png',
		href: 'https://journal.credit.club/kak-otkazatsya-ot-strahovki-po-kreditu',
		imageClassName: styles.image,
	},
	{
		content: 'Что такое простая электронная подпись и зачем она нужна',
		imageUrl: 'journal-item3-new.jpg',
		imageUrlMobile: 'journal-item3-mob-new.png',
		href: 'https://journal.credit.club/kak-vzyat-dengi-pod-zalog-nedvizhimosti',
		imageClassName: styles.image,
	},
]

export const youtuVideo = {
	src: '/videos/How-mortgage-lending-works.mp4',
	poster: '/images/how-loan-works.jpg',
}

export const comfortTerms: ComfortTerm[] = [
	{
		id: '1',
		title: 'Быстрое одобрение',
		icon: <TimeInShape />,
	},
	{
		id: '2',
		title: 'Любая кредитная история',
		icon: <DocsInShape />,
	},
	{
		id: '3',
		title: 'Доступные кредитные ставки',
		icon: <CashInShape />,
	},
	{
		id: '4',
		title: 'Никаких брокерских комиссий',
		icon: <PercentRoundInShape />,
	},
	{
		id: '5',
		title: 'Без посещения офиса по всей России',
		icon: <OfficeInShape />,
	},
	{
		id: '6',
		title: 'Высокая вероятность одобрения',
		icon: <GrowInShape />,
	},
]
